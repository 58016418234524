import { useEffect, useState } from 'react';
import useSWR from 'swr';
import axiosInstance from './axiosInstance';

export async function fetcher(path, init) {
  const response = await axiosInstance(path, init);
  return response?.data;
}

export const post = makeMutationMethod('POST');
export const put = makeMutationMethod('PUT');
export const patch = makeMutationMethod('PATCH');
export const del = makeMutationMethod('DELETE');

export function makeMutationMethod(method) {
  return (path, data = {}, options) => {
    const init = { method, data, ...options };
    return fetcher(path, init);
  };
}

export const useExtendedSWR = (url, options) => {
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);
  const result = useSWR(url, fetcher, options);

  useEffect(() => {
    result.data && !hasInitiallyLoaded && setHasInitiallyLoaded(true);
  }, [hasInitiallyLoaded, result.data]);

  return { ...result, hasInitiallyLoaded };
};
