import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { HelmetProvider } from 'react-helmet-async';

import MainLayout from 'components/MainLayout';
import { NotificationsProvider } from 'components/common/Notifications';
import { CurrencyProvider } from 'providers/CurrencyProvider';
import { CookieConsent } from 'components/common/CookieConsent';
import { Banner } from 'components/Banner/Banner';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { activateLocale } from 'i18n.setup';
import ApiErrorHandler from './components/ApiErrorHandler';
import { AuthProvider } from 'providers/AuthProvider';
import { AnalyticsProvider } from 'providers/AnalyticsProvider';
import { AliasProvider } from './providers/AliasProvider';

const history = createBrowserHistory();

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(history),
      }),
    ],
    environment: process.env.NODE_ENV,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    enabled: process.env.REACT_APP_ENV === 'production',
    beforeSend: (event) => {
      if (event?.response?.status) {
        if (event.response.status >= 500) {
          return event.response.status === 512 ? null : event;
        }
        return null;
      }
      return event;
    },
  });
}

function App() {
  const [isLocaleActivated, setIsLocaleActivated] = useState(false);

  const activateLocaleHandler = async () => {
    await activateLocale();
    setIsLocaleActivated(true);
  };

  useEffect(() => {
    activateLocaleHandler();
  }, []);

  return isLocaleActivated ? (
    <React.StrictMode>
      <HelmetProvider>
        <BrowserRouter history={history}>
          <I18nProvider i18n={i18n} forceRenderOnLocaleChange>
            <ErrorBoundary>
              <NotificationsProvider>
                <AuthProvider>
                  <ApiErrorHandler>
                    <CurrencyProvider>
                      <AnalyticsProvider>
                        <Banner />
                        <AliasProvider>
                          <MainLayout />
                        </AliasProvider>
                        <CookieConsent />
                      </AnalyticsProvider>
                    </CurrencyProvider>
                  </ApiErrorHandler>
                </AuthProvider>
              </NotificationsProvider>
            </ErrorBoundary>
          </I18nProvider>
        </BrowserRouter>
      </HelmetProvider>
    </React.StrictMode>
  ) : null;
}

export default App;
