import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { XLg } from 'react-bootstrap-icons';
import Toast from './Toast';

export function NotificationsProvider({ children, autoHideDelay = 5000 }) {
  const [notifications, setNotifications] = useState([]);
  const [autoHide, setAutoHide] = useState(false);

  const removeOld = useCallback(
    (notifications) =>
      notifications.filter(
        (notification) => notification.created > new Date() - autoHideDelay
      ),
    [autoHideDelay]
  );

  const addNotification = useCallback(
    (msg, type = 'alert', persistMessage = false) => {
      setAutoHide(!persistMessage);
      setNotifications((notifications) =>
        removeOld([
          {
            msg,
            type,
            created: new Date(),
          },
          ...notifications,
        ])
      );
    },
    [removeOld]
  );

  const removeNotification = (created) => {
    setAutoHide(true);
    setNotifications((notifications) =>
      removeOld(
        notifications.filter((notification) => notification.created !== created)
      )
    );
  };

  const ctx = useMemo(
    () => ({
      addNotification,
    }),
    [addNotification]
  );

  return (
    <NotificationsContext.Provider value={ctx}>
      {notifications.map((notification) => (
        <Toast
          autoHide={autoHide}
          delay={autoHideDelay}
          onClose={() => removeNotification(notification.created)}
          className="d-inline-block m-1"
          variant={notification.type.toLowerCase()}
          key={`${notification.created}-${Math.random()}`}
        >
          {!autoHide && (
            <div className="flex justify-end">
              <div
                className="cursor-pointer"
                onClick={() => removeNotification(notification.created)}
              >
                <XLg />
              </div>
            </div>
          )}
          <div dangerouslySetInnerHTML={{ __html: notification.msg }} />
        </Toast>
      ))}
      {children}
    </NotificationsContext.Provider>
  );
}

export function useNotifications() {
  return useContext(NotificationsContext);
}

const NotificationsContext = createContext({
  addNotification: () => {},
});
