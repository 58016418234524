import { Trans } from '@lingui/macro';
import classnames from 'classnames';

import { useCurrency } from 'hooks/useCurrency';
import useCountry from 'hooks/useCountry';
import PriceFormat from "../common/PriceFormat";

export default function DeliveryFrom({ className }) {
  const country = useCountry();
  const currency = useCurrency();

  if (country == null) return null;

  let flag = country.toLowerCase();

  if (flag === 'xx') return null;

  if (currency.code === 'GBP') {
    flag = 'gb';
  } else if (currency.code === 'USD') {
    if (flag !== 'au' && flag !== 'ca') {
      flag = 'us';
    }
  }

  return (
    <p
      className={classnames(
        className,
        'flex items-center italic font-sans text-sm'
      )}
    >
      <img
        src={`${process.env.PUBLIC_URL}/static/img/flags/${flag}.gif`}
        alt={`${flag} flag`}
        className="mr-2"
      />
      <Trans>Delivery from&nbsp;<PriceFormat value={currency.deliveryFrom} />!</Trans>
    </p>
  );
}
