export function priceFormat(value, includeDecimals = true, currency = null) {
  if (value == null) return '';
  if (typeof value === 'string') value = parseFloat(value);

  const decimals = (includeDecimals && value % 1 !== 0) ? 2 : 0;

  const price = value
    .toFixed(decimals)
    .replace(/\d(?=(\d{3})+(\.|$))/g, '$& ')
    .replace(',', '.');

  if (currency?.toLowerCase() === 'eur') {
    return `€${price}`;
  }

  return price;
}
