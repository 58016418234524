import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import routeNames from 'router/routeNames.json';

export default function PrivateRoute({ children }) {
  const location = useLocation();
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? (
    children
  ) : (
    <Navigate to={routeNames.login} state={{ from: location.pathname }} />
  );
}
