import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import qwerteeBot from 'assets/qwerteeBot.png';

export default function ErrorBoundary({ children }) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError, eventId }) => (
        <ErrorBoundaryFallback resetError={resetError} eventId={eventId} />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

function ErrorBoundaryFallback({ resetError, eventId }) {
  const location = useLocation();
  const [initialLocation] = useState(location);

  useEffect(() => {
    if (location !== initialLocation) {
      resetError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLocation, location]);

  return (
    <div className="mx-auto [max-width:1060px] pb-6 pt-1.5 sm:pt-10 flex">
      <div className="hidden md:block [width:379px] mr-4 flex-shrink-0">
        <img src={qwerteeBot} alt="Qwertee Bot" />
      </div>
      <div>
        <h2 className="text-5xl font-bold mb-8">
          <Trans>Error!</Trans>
        </h2>
        <p className="my-4">
          <Trans>Oops something has gone wrong, please try again!</Trans>
        </p>
        <Trans>If you contact us please provide us with this: {eventId}</Trans>
        <p></p>
      </div>
    </div>
  );
}
