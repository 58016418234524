export function getCookie(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}

export function getAsyncCookie(name, allowedValues) {
  const promise = new Promise((resolve, reject) => {
    const cookie = getCookie(name);

    if (cookie) {
      if (!allowedValues || allowedValues.includes(cookie)) {
        return resolve(cookie);
      }
    }
    const maxRetries = 50;
    let counter = 0;

    const interval = setInterval(() => {
      const asyncCookie = getCookie(name);

      if (asyncCookie) {
        if (!allowedValues || allowedValues.includes(asyncCookie)) {
          clearInterval(interval);
          return resolve(asyncCookie);
        }
      }
      if (counter >= maxRetries) {
        reject('Request for cookie timed out.');
      }

      counter++;
    }, 200);
  });

  return promise;
}
