import useSWR from 'swr';
import { fetcher, put } from '../base';

const url = 'countries';

export const useCountries = () => {
  const { data: countries } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
  });

  const changeCountry = (country) => put(url, { country });

  return {
    countries,
    changeCountry,
  };
};
