import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { t } from '@lingui/macro';
import { useAuth } from 'hooks/useAuth';
import axiosInstance from 'api/axiosInstance';

import routeNames from 'router/routeNames.json';
import { useNotifications } from './common/Notifications';

const defaultErrorMessage = t`Something went wrong!`;

export default function ApiErrorHandler({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { addNotification } = useNotifications();
  const { logout, isLoggedIn } = useAuth();

  const errorHandler = useCallback(
    (error) => {
      if (error?.code === 'ERR_CANCELED') {
        return;
      }

      if (error?.response?.status === 403) {
        isLoggedIn && logout();
        navigate(routeNames.login, { state: { from: location.pathname } });
        return;
      }

      let errorMessage;

      if (error.response?.data?.message != null) {
        errorMessage = error.response?.data?.message;
      } else if (error.response?.data != null) {
        errorMessage = error.response?.data;
      }

      error?.response?.status !== 512 &&
        error?.response?.status !== 404 &&
        error?.response?.status !== 401 &&
        Sentry.captureException(errorMessage || JSON.stringify(error), {
          tags: {
            errorType: 'PWA API Error',
          },
        });

      error?.response?.status !== 404 &&
        error?.response?.status !== 410 &&
        addNotification(
          errorMessage ? errorMessage.toString() : defaultErrorMessage,
          'danger',
          error.response?.data?.persistMessage
        );

      return Promise.reject(error);
    },
    [addNotification, isLoggedIn, location.pathname, logout, navigate]
  );

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      errorHandler
    );
    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, [errorHandler]);

  return children;
}
