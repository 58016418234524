import { createContext, useCallback, useMemo } from 'react';
import { useSegment } from './useSegment';
import { useGtm } from './useGtm';

export const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
  const segment = useSegment();
  const gtm = useGtm();

  const getAnonymousId = useCallback(async () => {
    const analytics = segment.getAnalytics();
    if (!analytics || !analytics.instance) return;
    const user = await analytics.user();
    return user?.anonymousId();
  }, [segment]);

  const cartViewedAction = useCallback(
    (items) => {
      segment.cartViewedAction(items);
      gtm.cartViewedAction(items);
    },
    [gtm, segment]
  );

  const orderCompleteAction = useCallback(
    (order) => {
      segment.orderCompleteAction(order);
      gtm.orderCompleteAction(order);
    },
    [segment, gtm]
  );

  const productViewAction = useCallback(
    (product) => {
      segment.productViewAction(product);
      gtm.productViewAction(product);
    },
    [segment, gtm]
  );

  const addToCartAction = useCallback(
    (order) => {
      segment.addToCartAction(order);
      gtm.addToCartAction(order);
    },
    [segment, gtm]
  );

  const buyNowButtonClickAction = useCallback(
    (product) => {
      gtm.buyNowButtonClickAction(product);
    },
    [gtm]
  );

  const contextValue = useMemo(
    () => ({
      addToCartAction,
      buyNowButtonClickAction,
      cartViewedAction,
      orderCompleteAction,
      productViewAction,
      getAnonymousId,
    }),
    [
      addToCartAction,
      buyNowButtonClickAction,
      cartViewedAction,
      orderCompleteAction,
      productViewAction,
      getAnonymousId,
    ]
  );

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};
