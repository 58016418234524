import { Route, Routes } from 'react-router-dom';
import { oldSiteRoutesConfig } from './oldSiteRoutesConfig';
import { routesConfig } from './routesConfig';

const Router = () => {
  return (
    <Routes>
      {[...routesConfig, ...oldSiteRoutesConfig].map(
        ({ path, element: Element }) => (
          <Route key={path} path={path} element={Element} />
        )
      )}
    </Routes>
  );
};

export default Router;
