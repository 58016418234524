import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

const Toast = ({ autoHide, delay, onClose, className, variant, children }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    autoHide &&
      delay &&
      setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, delay);
  }, [autoHide, delay, onClose]);

  return isVisible ? (
    <div
      className={classNames(
        'w-96 p-3 fixed rounded-md right-4 top-4 z-[200]',
        className,
        {
          'bg-red-500': variant === 'danger',
          'bg-slate-200': variant === 'alert',
        }
      )}
    >
      {children}
    </div>
  ) : null;
};

export default Toast;
