import { useCurrency } from 'hooks/useCurrency';
import { priceFormat } from 'helpers/l10n';

export default function PriceFormat({
  value,
  className,
  includeDecimals = true,
}) {
  const { symbol, right } = useCurrency();

  return right ? (
    <span className={className}>
      {priceFormat(value, includeDecimals)}
      {symbol}
    </span>
  ) : (
    <span className={className}>
      {symbol}
      {priceFormat(value, includeDecimals)}
    </span>
  );
}
