import { fetcher } from '../base';

const url = 'currencies';

export const useCurrencies = () => {
  const getCurrencies = () => {
    return fetcher(url);
  };

  return {
    getCurrencies,
  };
};
