import useSWR from 'swr';
import { fetcher } from '../base';

export function useAddress(skip = false) {
  return useSWR(
    !skip && '/checkout/address',
    fetcher,
    { revalidateOnFocus: false }
  );
}
