import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use((request) => {
  if (process.env.REACT_APP_BASIC_AUTH_USER) {
    request.auth = {
      username: process.env.REACT_APP_BASIC_AUTH_USER,
      password: process.env.REACT_APP_BASIC_AUTH_PW,
    };
  }

  return request;
});

export default axiosInstance;
