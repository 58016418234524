import { i18n } from '@lingui/core';
import { en, it } from 'make-plural/plurals';
import { detect, fromNavigator, fromStorage } from '@lingui/detect-locale';

i18n.loadLocaleData({
  en: { plurals: en },
  it: { plurals: it },
});

export const locales = {
  en: 'English',
  it: 'Italian',
};

export async function activateLocale(
  locale = detect(fromStorage('lang'), fromNavigator(), 'en')
) {
  if (locales[locale] == null) {
    locale = 'en';
  }

  const { messages } = await import(`./locales/${locale}`);

  i18n.load(locale, messages);
  i18n.activate(locale);

  if (typeof window !== 'undefined' && window.localStorage?.setItem != null) {
    window.localStorage.setItem('lang', locale);
  }
}
