import classNames from 'classnames';

export const Container = ({ children, className, ...props }) => (
  <div
    className={classNames(
      'max-w-[500px] md:max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto',
      className
    )}
    {...props}
  >
    {children}
  </div>
);
