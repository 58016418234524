import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import routeNames from 'router/routeNames.json';

export const OldSiteRedirects = ({ redirectTo }) => {
  const { productSlug } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const type = searchParams.get('type');

    if (type && type === 'sticker') {
      navigate(`${routeNames.shopStickers}/${productSlug}`);
    } else {
      navigate(`${redirectTo}/${productSlug}`);
    }
  }, [navigate, productSlug, redirectTo, searchParams]);

  return null;
};
