import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const sessionStorageItem = 'isRedirectedToMaster';

export const RedirectToMaster = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (sessionStorage.getItem(sessionStorageItem) === 'true') {
      sessionStorage.setItem(sessionStorageItem, 'false');
      window.history.back();
    } else {
      sessionStorage.setItem(sessionStorageItem, 'true');
      window.location.href = `${process.env.REACT_APP_SERVER_SITE}${pathname}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
