import classNames from 'classnames';
import { useField } from 'formik';
import React, { forwardRef } from 'react';

const Checkbox = forwardRef(
  ({ id, label, inputClassName, labelClassName, ...props }, ref) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    const hasError = meta.touched && meta.error;

    return (
      <div>
        <div className="flex items-center">
          <input
            className={classNames('mr-1', inputClassName)}
            id={id}
            type="checkbox"
            ref={ref}
            {...props}
            {...field}
          />
          <label className={classNames('text-sm', labelClassName)} htmlFor={id}>
            {label}
          </label>
        </div>
        {hasError && (
          <span className="text-sm mb-1 text-red-600">{meta.error}</span>
        )}
      </div>
    );
  }
);

export default Checkbox;
