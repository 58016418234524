export const getDesignsImageUrl = (img, width, height) =>
  `${process.env.REACT_APP_IMG_SERVER_SITE}/images/designs/product-thumbs/${img.id}-${img.name}-${width}x${height}.jpg`;

export const getPopupImageUrl = (imgName) =>
  `${process.env.REACT_APP_IMG_SERVER_SITE}/images/popup/${imgName}.jpg`;

export const getDesignThumbImgUrl = ({ lastModified, id, width, height }) =>
  `${process.env.REACT_APP_IMG_SERVER_SITE}/images/designs/product-thumbs/${lastModified}-${id}-thumbs-${width}x${height}.jpg`;

export const getDesignFullImgUrl = ({ lastModified, id, width, height }) =>
  `${process.env.REACT_APP_IMG_SERVER_SITE}/images/designs/product-thumbs/${lastModified}-${id}-full-${width}x${height}.jpg`;
