import { Suspense } from 'react';
import TopNav from 'components/menu/TopNav';
import Header from 'components/menu/Header/Header';
import Footer from 'components/menu/Footer/Footer';
import CountrySwitcher from './menu/CountrySwitcher';
import Router from 'router/Router';
import { Add } from './Add/Add';
import { useFeatureFlags } from 'api/useFeatureFlags';

const Page = () => {
  const displayCountrySwitcher =
    process.env.REACT_APP_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'staging';
  const { data: featureFlags } = useFeatureFlags();

  return featureFlags ? (
    <div className="px-0 py-0 flex flex-col h-full">
      <Suspense fallback={<div></div>}>
        <div>
          {displayCountrySwitcher && <CountrySwitcher />}
          <TopNav />
          <Header />
        </div>
        <main className="flex-1">
          <Router />
        </main>
        <Footer />
        <Add />
      </Suspense>
    </div>
  ) : null;
};

export default Page;
