import { useCallback, useMemo } from 'react';
import { useCurrency } from 'hooks/useCurrency';

window.dataLayer = window.dataLayer || [];

const addToDataLayer = (event, ecommerce) => {
  window.dataLayer.push({
    event: event,
    ecommerce: ecommerce,
  });
};

export const useGtm = () => {
  const currency = useCurrency();

  const cartViewedAction = useCallback(
    (items) => {
      addToDataLayer('checkout', {
        currencyCode: currency.code,
        checkout: {
          actionField: { step: 1 },
          products: items.map((item) => {
            return {
              name: item.name,
              quantity: item.num,
              id: item.id,
              price: Number(item.price),
              variant: item.size,
              category: item.sizeFull ? item.sizeFull.split(' ')[0] : '',
            };
          }),
        },
      });
    },
    [currency.code]
  );

  const productViewAction = useCallback(
    (product) => {
      addToDataLayer('productView', {
        currencyCode: currency.code,
        detail: {
          actionField: {},
          products: [
            {
              name: product.name,
              id: product.id,
              dimension1: product.user?.id.toString(),
              dimension2: product.user?.username,
              category: product.types[0]?.name,
            },
          ],
        },
      });
    },
    [currency.code]
  );

  const addToCartAction = useCallback(
    (order) => {
      addToDataLayer('addToCart', {
        currencyCode: currency.code,
        add: {
          products: [
            {
              name: order.name,
              id: order.productId,
              price: Number(order.price[currency.code]),
              category: order.category,
              variant: order.variant,
              quantity: order.quantity,
            },
          ],
        },
      });
    },
    [currency.code]
  );

  const buyNowButtonClickAction = useCallback(
    (product) => {
      addToDataLayer('buyNowClick', {
        currencyCode: currency.code,
        click: {
          actionField: {},
          products: [
            {
              name: product.name,
              id: product.id,
              dimension1: product.user?.id.toString(),
              dimension2: product.user?.username,
              category: product.types[0].name,
            },
          ],
        },
      });
    },
    [currency.code]
  );

  const orderCompleteAction = useCallback(
    (order) => {
      addToDataLayer('transaction', {
        currencyCode: currency.code,
        purchase: {
          actionField: {
            id: order.id,
            revenue: order.total,
            tax: order.tax,
            shipping: order.shipping_cost,
            coupon: order.discount_code,
          },
          products: order.items.map((item) => ({
            name: item.name,
            quantity: item.quantity,
            id: item.product_id,
            price: item.unit_price,
            variant: item.size,
            category: item.sizeFull && item.sizeFull.split(' ')[0],
          })),
        },
      });
    },
    [currency.code]
  );

  return useMemo(
    () => ({
      cartViewedAction,
      productViewAction,
      addToCartAction,
      buyNowButtonClickAction,
      orderCompleteAction,
    }),
    [
      cartViewedAction,
      productViewAction,
      addToCartAction,
      buyNowButtonClickAction,
      orderCompleteAction,
    ]
  );
};
