import { Trans } from '@lingui/macro';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { YourCartLink } from './YourCartLink';
import LocaleSwitcher from 'components/common/LocaleSwitcher';
import CurrencySwitcher from './CurrencySwitcher';
import DeliveryFrom from './DeliveryFrom';
import { useAuth } from 'hooks/useAuth';
import routeNames from 'router/routeNames.json';

const Separator = () => (
  <div className="h-4 w-px md:w-[2px] bg-white mx-[0.3rem]" />
);

export default function TopNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, isLoggedIn, user } = useAuth();

  const handleLogout = async () => {
    await logout();
    navigate(routeNames.home);
  };

  const linkClass =
    'text-[0.6rem] sm:text-xs md:text-[0.8rem] text-white font-semibold font-sans no-underline hover:underline whitespace-nowrap';

  return (
    <div className="p-1 [min-height:30px] text-white bg-qw-blue-500 flex items-center">
      <div className="w-full [max-width:1200px] mx-auto flex">
        <div className="lg:flex-1" />
        <div className="hidden lg:block">
          <DeliveryFrom className="text-white font-medium" />
        </div>
        <nav className="flex-1 px-1 flex flex-wrap justify-center md:justify-end items-center text-[0.8rem]">
          <YourCartLink className={linkClass} />
          <Separator />
          {isLoggedIn ? (
            <>
              <button onClick={handleLogout} className={linkClass}>
                <Trans>Log out</Trans>
              </button>
              <Separator />
              <Link to={routeNames.userProfile} className={linkClass}>
                <Trans>Your Account</Trans>
              </Link>
              <Separator />
              <a
                href={`${process.env.REACT_APP_SERVER_SITE}${routeNames.profile}/${user?.id}`}
                className={linkClass}
              >
                <Trans>Profile</Trans>
              </a>
            </>
          ) : (
            <>
              <Link
                to={routeNames.login}
                state={{ from: location.pathname }}
                className={linkClass}
              >
                <Trans>Log in</Trans>
              </Link>
              <Separator />
              <Link to={routeNames.register} className={linkClass}>
                <Trans>Join Us</Trans>
              </Link>
            </>
          )}
          <Separator />
          <CurrencySwitcher className={linkClass} />
          {process.env.REACT_APP_MENU_LANGUAGE === 'true' && (
            <div className="hidden md:flex">
              <Separator />
              <LocaleSwitcher className={linkClass} />
            </div>
          )}
        </nav>
      </div>
    </div>
  );
}
