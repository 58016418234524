import { useBanner } from 'api/header/useBanner';
import { useEffect, useState } from 'react';
import { getCookie } from 'helpers/cookies';
import { t, Trans } from '@lingui/macro';

const bannerLastFetchCookie = 'banner';
const bannerLastUpdateCookie = 'bannerUpdated';

function getBannerText(banner) {
  const html = banner.data.html_text;
  switch (html) {
    case 'All orders shipped from within the EU!':
      return t`All orders shipped from within the EU!`;
    default:
      return html;
  }
}

export const Banner = () => {
  const [fetchBanner, setFetchBanner] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  // fetch banner data at least once every 24 hours to check for updates
  const banner = useBanner({ skip: !fetchBanner });
  const bannerLastUpdate = banner.data?.last_edit;

  const handleHide = () => {
    const now = new Date();
    document.cookie = `${bannerLastFetchCookie}=${now.valueOf()}; SameSite=None; Secure`;
    if (bannerLastUpdate != null) {
      document.cookie = `${bannerLastUpdateCookie}=${bannerLastUpdate}; SameSite=None; Secure`;
    }
    setFetchBanner(false);
    setShowBanner(false);
  };

  useEffect(() => {
    const bannerLastFetched = getCookie(bannerLastFetchCookie);
    if (!bannerLastFetched) setFetchBanner(true);

    const date = new Date(bannerLastFetched * 1000);
    if (date.valueOf() / 1000 < Date.now() - 60) setFetchBanner(true);
  }, []);

  useEffect(() => {
    if (bannerLastUpdate == null) return;

    const bannerLastUpdateCached = getCookie(bannerLastUpdateCookie);
    if (
      !bannerLastUpdateCached ||
      new Date(bannerLastUpdateCached) < new Date(bannerLastUpdate)
    )
      setShowBanner(true);
  }, [bannerLastUpdate]);

  if (!showBanner || banner.data == null) return null;

  return (
    <div className="p-2 bg-black text-sm text-white text-center">
      <p dangerouslySetInnerHTML={{ __html: getBannerText(banner) }} />
      <button onClick={handleHide} className="font-bold">
        <Trans>Hide</Trans>
      </button>
    </div>
  );
};
