import { createRoot } from 'react-dom/client';
import React from 'react';

import App from 'App';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'variables.css';
import 'index.css';

// Disable native scroll restoration behavior as it's handled by polyfill
// https://www.npmjs.com/package/delayed-scroll-restoration-polyfill#disabling-native-implementations

(async () => {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }

  if ('ResizeObserver' in window === false) {
    const module = await import('@juggle/resize-observer');
    window.ResizeObserver = module.ResizeObserver;
  }

  const root = createRoot(document.getElementById('root'));

  root.render(<App />);
})();
