import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const RedirectMainUrl = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(pathname.replace('/pwa', ''));
  }, [navigate, pathname]);

  return null;
};
