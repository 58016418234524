import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useCurrency } from 'hooks/useCurrency';
import { useCurrencies } from 'api/currency/useCurrencies';
import { Menu } from '@headlessui/react';
import { CaretDownFill } from 'react-bootstrap-icons';

export default function CurrencySwitcher({ className }) {
  const { setCurrency, code: activeCode } = useCurrency();
  const [currencies, setCurrencies] = useState();
  const { getCurrencies } = useCurrencies();

  const handleSetCurrency = async (currency) => {
    await setCurrency(currency);
    window.location.reload();
  };

  const getAllCurrencies = useCallback(async () => {
    if (!currencies) {
      const { currencies } = await getCurrencies();
      setCurrencies(currencies);
    }
  }, [currencies, getCurrencies]);

  useEffect(() => {
    getAllCurrencies();
  }, [getAllCurrencies]);

  if (!currencies || !activeCode) return '';
  return (
    <Menu>
      <div className="relative">
        <Menu.Button className={classNames('flex items-center', className)}>
          {activeCode} ({currencies[activeCode].sym})
          <CaretDownFill className="ml-1 h-3" />
        </Menu.Button>

        <Menu.Items className="absolute right-0 mt-2 py-2 w-40 origin-top-right divide-y divide-gray-100 z-20 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-base">
          {Object.keys(currencies).map((currency) => (
            <Menu.Item
              className="text-gray-900 px-4 py-1 cursor-pointer hover:bg-gray-200"
              key={currency}
              onClick={() => handleSetCurrency(currency)}
            >
              <div>
                {currency} ({currencies[currency].sym})
              </div>
            </Menu.Item>
          ))}
        </Menu.Items>
      </div>
    </Menu>
  );
}
