import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const FormResetOnRouteChangeHandler = () => {
  const { resetForm } = useFormikContext();
  const location = useLocation();

  useEffect(() => {
    resetForm();
  }, [location, resetForm]);

  return null;
};
