import { createContext, useCallback, useMemo } from 'react';
import { getCookie } from '../helpers/cookies';

export const AliasContext = createContext();

export const AliasProvider = ({ children }) => {
  const setAlias = useCallback((alias) => {
    const cookie = btoa(
      JSON.stringify({ expires: alias.expires, product: alias.id })
    );
    setCookie(cookie);
  }, []);

  const getAlias = useCallback(() => {
    const cookie = getCookie('alias');
    if (!cookie) return;
    const alias = JSON.parse(atob(cookie));
    if (!alias?.expires) return;
    const expire = new Date(alias.expires);
    if (expire < new Date()) {
      setCookie('');
      return;
    }
    return alias;
  }, []);

  const contextValue = useMemo(
    () => ({
      setAlias,
      getAlias,
    }),
    [setAlias, getAlias]
  );

  return (
    <AliasContext.Provider value={contextValue}>
      {children}
    </AliasContext.Provider>
  );
};

function setCookie(cookie) {
  const secondsInADay = 3600 * 24;
  document.cookie = `alias=${cookie};path=/;max-age=${secondsInADay};`;
}
