import classnames from 'classnames';
import { forwardRef } from 'react';

export const Button = forwardRef((props, ref) => {
  const {
    className,
    children,
    block,
    variant = 'blue',
    size = 'base',
    as: Component = 'button',
    ...rest
  } = props;

  const _className = classnames(className, {
    'py-0 px-3 text-lg rounded-full font-black': size === 'sm',
    'py-2 px-12 rounded-xl uppercase font-sans font-bold text-xl':
      size === 'lg',
    'p-4 text-4xl font-bold font-sans text-shadow': size === 'lg-buy',
    'rounded-lg': size === 'lg-buy' && !block,
    'rounded py-1 px-4': size === 'base',
    'bg-qw-blue-500 text-white hover:bg-qw-green': variant === 'blue',
    'bg-gray-100 hover:bg-gray-200': variant === 'light',
    'bg-gray-800 text-white hover:bg-gray-600': variant === 'dark',
    'bg-black text-white px-4': variant === 'black',
    'rounded text-qw-blue-500 hover:underline disabled:text-black':
      variant === 'link',
    'block w-full rounded-2xl': block,
  });

  return (
    <Component className={_className} ref={ref} {...rest}>
      {children}
    </Component>
  );
});
