import { Navigate } from 'react-router-dom';
import { OldSiteRedirects } from './components/OldSiteRedirects';
import { oldSiteRouteNames } from './oldSiteRouteNames';
import routeNames from './routeNames.json';

export const oldSiteRoutesConfig = [
  {
    path: routeNames.checkout,
    element: (
      <Navigate to={`${routeNames.checkout}${routeNames.checkoutForm}`} />
    ),
  },
  {
    path: oldSiteRouteNames.shopKids,
    element: <Navigate to={routeNames.shopKids} />,
  },
  {
    path: oldSiteRouteNames.shopTees,
    element: <Navigate to={routeNames.shopTees} />,
  },
  {
    path: oldSiteRouteNames.shopPrints,
    element: <Navigate to={routeNames.shopPrints} />,
  },
  {
    path: oldSiteRouteNames.shopStickers,
    element: <Navigate to={routeNames.shopStickers} />,
  },
  {
    path: oldSiteRouteNames.product,
    element: <OldSiteRedirects redirectTo={routeNames.shopTees} />,
  },
  {
    path: oldSiteRouteNames.print,
    element: <OldSiteRedirects redirectTo={routeNames.shopPrints} />,
  },
];
