import React from 'react';

export const MenuIcon = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 360 240"
    height="21"
  >
    <g>
      <rect x="0" y="0" style={{ fill: '#FFF' }} width="360" height="40" />
      <rect x="0" y="100" style={{ fill: '#FFF' }} width="360" height="40" />
      <rect x="0" y="200" style={{ fill: '#FFF' }} width="360" height="40" />
    </g>
  </svg>
);
