import { useState } from 'react';
import { t } from '@lingui/macro';
import { Modal } from 'components/common/Modal/Modal';

export default function WhatIsQwerteeModal({ children, linkClassName }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <span className={linkClassName} onClick={() => setShow(true)}>
        {children}
      </span>

      <Modal
        title={t`What is Qwertee?`}
        onClose={() => setShow(false)}
        isOpen={show}
      >
        <div className="relative max-w-lg w-full h-80 m-auto">
          <iframe
            className="absolute inset-0 w-full h-full"
            src="https://www.youtube.com/embed/baLGNa1kckE"
            title={t`YouTube video player`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Modal>
    </>
  );
}
