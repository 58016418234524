import { useEffect, useState } from 'react';
import { getCookie } from 'helpers/cookies';

export function useConsent(name) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const cookie = getCookie(name);
    if (!cookie) setShow(true);
  }, [name, setShow]);

  const handleConsent = () => {
    document.cookie = `${name}=1; SameSite=None; Secure`;
    setShow(false);
  };

  return {
    show,
    handleConsent,
  };
}
