import React from 'react';
import { useField } from 'formik';
import classNames from 'classnames';

export default function TextInput(props) {
  const {
    id,
    name,
    label,
    type,
    disabled,
    placeholder,
    className,
    containerClassName,
    errorClassName,
    message,
    row,
    labelSize,
    ps,
    ...rest
  } = props;
  const [field, meta] = useField(props);
  const hasError = meta.touched && meta.error;

  return (
    <div
      className={classNames(
        'mb-2 flex',
        { 'flex-row': row, 'flex-col': !row },
        containerClassName
      )}
    >
      {label && (
        <label
          htmlFor={id}
          className={classNames('whitespace-nowrap', {
            'text-xs xs:text-sm': !labelSize,
            'text-l min-w-[10rem]': labelSize === 'l',
            'text-xl': labelSize === 'xl',
            'mr-1': row,
          })}
        >
          {label}
        </label>
      )}
      <input
        className={classNames(
          'h-7 border border-gray-500 w-full text-base xs:text-xl p-[0.125rem] rounded-[0.1875rem]',
          className,
          { '!border-qw-red': hasError }
        )}
        id={id}
        name={name}
        type={type || 'text'}
        disabled={disabled}
        placeholder={placeholder}
        {...field}
        {...rest}
      />
      {(message || hasError) && (
        <span
          className={classNames('text-sm mb-1 text-qw-red', errorClassName)}
        >
          {meta.error || message}
        </span>
      )}
      <div className="text-xl ml-2">{ps}</div>
    </div>
  );
}
