import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import routeNames from 'router/routeNames.json';

export const ReferralRedirect = () => {
  const { referralCode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(routeNames.home, { state: { referralCode } });
  }, [navigate, referralCode]);

  return null;
};
